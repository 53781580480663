<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {

  },
  provide () {
    return {
      reload: this.reload
    }
  },
  created () {
    if (sessionStorage.getItem('roles')) {
      this.$store.commit('updataState')
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
}
</style>
