import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 设置属性，用于保存后台接口返回的权限数据
    permission: [],
    order_company: '',
    auth_type: 'order',
    roles: []
  },
  getters: {
    roles: (state) => state.roles,
    isOrderCompany () {
      if (sessionStorage.getItem('order_company')) {
        return false
      } else {
        return true
      }
    }
  },
  mutations: {
    SET_ROLES: (state, roles) => {
      state.roles = roles
      sessionStorage.setItem('roles', JSON.stringify(roles))
    },
    SET_PERMISSION (state, permission) {
      // permission 为传入的权限标识集合
      // 传入的权限集合 赋值给状态中的 permission
      state.permission = permission
    },
    SET_ORDER_COMPANY (state, value) {
      console.log('我是SET_ORDER_COMPANY')
      // 传入的权限集合 赋值给状态中的 value
      state.order_company = value
      sessionStorage.setItem('order_company', value)
    },
    CLEAR_INFO (state) {
      state.order_company = ''
      state.permission = []
    },
    updataState (state) {
      state.roles = JSON.parse(sessionStorage.getItem('roles'))
    //   console.log(state.roles, 'state.roles')
    }
  },
  actions: {
    SET_PERMISSION (context, permission) {
      // 提交到 mutation 中的 SET_PERMISSION 函数
      context.commit('SET_PERMISSION', permission)
    },
    SET_ORDER ({ commit }, value) {
      commit('SET_ORDER_COMPANY', value)
    },
    CLEAR ({ commit }) {
      commit('CLEAR_INFO')
    }
  },
  modules: {}
})
