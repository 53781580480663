import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

const routes = [{
  path: '/',
  component: () => import('@/views/Layout/Layout.vue'),
  redirect: '/system',
  children: [{
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home.vue'),
    children: [],
    meta: {
      bread: {
        title: '首页'
      },
      path: '/home'
    }
  }, // 首页
  {
    path: '/operation',
    // name: 'operation',
    redirect: '/operation/dispatch/orderScheduling',
    component: () => import('@/views/operation/operation.vue'),
    // component: () => import('@/views/Layout/Layout.vue'),
    meta: {
      bread: {
        title: '运营管理'
      },
      role: [],
      path: '/operation'
    },
    children: [{
      path: '/operation/dispatch',
      name: 'dispatch',
      component: () => import('@/views/dispatch/dispatch.vue'),
      children: [{
        path: '/operation/dispatch/orderScheduling',
        name: 'orderScheduling',
        component: () => import('@/views/orderScheduling/orderScheduling.vue'),
        meta: {
          bread: {
            title: '运营管理',
            children: {
              title: '调度中心',
              children: {
                title: '订单调度'
              }
            }
          }
        }
      }, // 订单调度
      {
        path: '/operation/dispatch/history',
        name: 'history',
        component: () => import('@/views/history/history.vue'),
        meta: {
          bread: {
            title: '运营管理',
            children: {
              title: '调度中心',
              children: {
                title: '历史调度'
              }
            }
          }
        }
      } // 历史调度
      ],
      meta: {
        bread: {
          title: '运营管理',
          children: {
            title: '调度中心'
          }
        }
      }
    } // 调度中心
    ]
  }, // 运营管理
  {
    path: '/system',
    name: 'system',
    // redirect: '/system/orderlist',
    component: () => import('@/views/system/system.vue'),
    meta: {
      bread: {
        title: '系统管理'
      },
      path: '/system'
    },
    children: [{
      path: '/system/settings',
      name: 'settings',
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '系统设置'
          }
        }
      },
      component: () => import('@/views/settings/settings.vue'),
      children: [{
        path: '/system/settings/userManage',
        name: 'userManage',
        component: () => import('@/views/userManage/userManage.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '系统设置',
              children: {
                title: '用户管理'
              }
            }
          }
        }
      }, // 用户管理
      {
        path: '/system/settings/menuManage',
        name: 'menuManage',
        component: () => import('@/views/menuManage/menuManage.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '系统设置',
              children: {
                title: '菜单管理'
              }
            }
          }
        }
      }, // 菜单管理
      {
        path: '/system/settings/characterManage',
        name: 'characterManage',
        component: () => import('@/views/characterManage/characterManage.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '系统设置',
              children: {
                title: '角色管理'
              }
            }
          }
        }
      }, // 角色管理
      {
        path: '/system/settings/diaryManage',
        name: 'diaryManage',
        component: () => import('@/views/diaryManage/diaryManage.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '系统设置',
              children: {
                title: '日志管理'
              }
            }
          }
        }
      }, // 日志管理
      {
        path: '/system/settings/applica',
        name: 'applica',
        component: () => import('@/views/applica/applica.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '系统设置',
              children: {
                title: '应用管理'
              }
            }
          }
        }
      } // 应用管理
      ]
    }, // 系统设置
    {
      path: '/system/orderlist',
      name: 'orderlist',
      // redirect: '/system/orderlist/orderStatistics',
      component: () => import('@/views/orderlist/orderlist.vue'),
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '订单管理'
          }
        }
      },
      children: [{ // 实时订单
        path: '/system/orderlist/instantOrder',
        name: 'instantOrder',
        component: () => import('@/views/instantOrder/instantOrder.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '实时订单'
              }
            }
          }
        }
      },
      { // 预付订单
        path: '/system/orderlist/orderPrepayment',
        name: 'orderPrepayment',
        component: () => import('@/views/orderPrepayment/orderPrepayment.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '预付订单'
              }
            }
          }
        }
      }, // 实时订单
      {
        path: '/system/orderlist/selectOrder',
        name: 'selectOrder',
        component: () => import('@/views/selectOrder/selectOrder.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单查看'
              }
            }
          }
        }
      }, // 订单查看
      {
        path: '/system/orderlist/bookingOrder',
        name: 'bookingOrder',
        component: () => import('@/views/bookingOrder/bookingOrder.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '预约订单'
              }
            }
          }
        }
      }, // 预约订单
      {
        path: '/system/orderlist/orderFollowPeo',
        name: 'orderFollowPeo',
        component: () => import('@/views/orderFollowPeo/orderFollowPeo.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单随车人员记录'
              }
            }
          }
        }
      }, // 订单随车人员记录
      {
        path: '/system/orderlist/orderCost',
        name: 'orderCost',
        component: () => import('@/views/orderCost/orderCost.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单其他费用记录'
              }
            }
          }
        }
      }, // 订单其他费用记录
      {
        path: '/system/orderlist/orderPharmacy',
        name: 'orderPharmacy',
        component: () => import('@/views/orderPharmacy/orderPharmacy.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单用药记录'
              }
            }
          }
        }
      }, // 订单用药记录
      {
        path: '/system/orderlist/orderPay',
        name: 'orderPay',
        component: () => import('@/views/orderPay/orderPay.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单支付记录'
              }
            }
          }
        }
      }, // 订单支付记录
      {
        path: '/system/orderlist/orderRefund',
        name: 'orderRefund',
        component: () => import('@/views/orderRefund/orderRefund.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单退款记录'
              }
            }
          }
        }
      }, // 订单退款记录
      {
        path: '/system/orderlist/orderStatistics',
        name: 'orderStatistics',
        component: () => import('@/views/orderStatistics/orderStatistics.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单统计管理'
              }
            }
          }
        }
      }, // 订单统计管理
      {
        path: '/system/orderlist/orderWaitRefund',
        name: 'orderWaitRefund',
        component: () => import('@/views/orderWaitRefund/orderWaitRefund.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单退款申请'
              }
            }
          }
        }
      }, // 订单退款
      {
        path: '/system/orderlist/orderRefundSure', // 订单退款确认
        name: 'orderRefundSure',
        component: () => import('@/views/orderRefundSure/orderRefundSure.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单退款确认'
              }
            }
          }
        }
      },
      {
        path: '/system/orderlist/orderinvoice', // 订单发票记录
        name: 'orderinvoice',
        component: () => import('@/views/orderinvoice/orderinvoice.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单发票记录'
              }
            }
          }
        }
      },
      {
        path: '/system/orderlist/organizationOrder', // 机构分成
        name: 'organizationOrder',
        component: () => import(
          '@/views/organizationOrder/organizationOrder.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '订单管理',
              children: {
                title: '订单分成统计'
              }
            }
          }
        }
      }
      ]
    }, // 订单管理
    {
      path: '/system/organization',
      name: 'organization',
      component: () => import('@/views/organization/organization.vue'),
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '机构管理'
          }
        }
      },
      children: [{
        path: '/system/organization/orManagement',
        name: 'orManagement',
        component: () => import('@/views/orManagement/orManagement.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '机构管理',
              children: {
                title: '机构信息管理'
              }
            }
          }
        }
      }, // 机构信息管理
      {
        path: '/system/organization/orCarManagement',
        name: 'orCarManagement',
        component: () => import('@/views/orCarManagement/orCarManagement.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '机构管理',
              children: {
                title: '机构车辆管理'
              }
            }
          }
        }
      }, // 机构车辆管理
      {
        path: '/system/organization/orStaffManagement',
        name: 'orStaffManagement',
        component: () => import(
          '@/views/orStaffManagement/orStaffManagement.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '机构管理',
              children: {
                title: '机构相关人员管理'
              }
            }
          }
        }
      }, // 机构相关人员管理
      {
        path: '/system/organization/orAccountManagement',
        name: 'orAccountManagement',
        component: () => import(
          '@/views/orAccountManagement/orAccountManagement.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '机构管理',
              children: {
                title: '机构账号管理'
              }
            }
          }
        }
      }, // 机构账号管理
      {
        path: '/system/organization/orDivideInto',
        name: 'orDivideInto',
        component: () => import('@/views/orDivideInto/orDivideInto.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '机构管理',
              children: {
                title: '机构分成管理'
              }
            }
          }
        }
      } // 机构信息管理
      ]
    }, // 机构管理
    {
      path: '/system/car',
      name: 'car',
      component: () => import('@/views/car/car.vue'),
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '车辆管理'
          }
        }
      },
      children: [{
        path: '/system/car/carInfo',
        name: 'carInfo',
        component: () => import('@/views/carInfo/carInfo.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车辆管理',
              children: {
                title: '车辆信息管理'
              }
            }
          }
        }
      }, // 车辆信息管理
      {
        path: '/system/car/ownermanagement',
        name: 'ownermanagement',
        component: () => import('@/views/ownermanagement/ownermanagement.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车辆管理',
              children: {
                title: '车主信息管理'
              }
            }
          }
        }
      }, // 车主信息管理管理
      {
        path: '/system/car/carhistoricalstate',
        name: 'carhistoricalstate',
        component: () => import(
          '@/views/carhistoricalstate/carhistoricalstate.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车辆管理',
              children: {
                title: '车辆历史状态管理'
              }
            }
          }
        }
      }, // 车辆历史状态管理
      {
        path: '/system/car/carLineManagement',
        name: 'carLineManagement',
        component: () => import(
          '@/views/carLineManagement/carLineManagement.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车辆管理',
              children: {
                title: '车辆上线信息管理'
              }
            }
          }
        }
      }, // 车辆上线信息管理
      {
        path: '/system/car/carannualinspectioninformation',
        name: 'carannualinspectioninformation',
        component: () => import(
          '@/views/carannualinspectioninformation/carannualinspectioninformation.vue'
        ),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车辆管理',
              children: {
                title: '车辆年检信息管理'
              }
            }
          }
        }
      }, // 车辆年检信息管理
      {
        path: '/system/car/carinsuranceinformation',
        name: 'carinsuranceinformation',
        component: () => import(
          '@/views/carinsuranceinformation/carinsuranceinformation.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车辆管理',
              children: {
                title: '车辆保险信息管理'
              }
            }
          }
        }
      }, // 车辆保险信息管理
      {
        path: '/system/car/carmaintenanceinformation',
        name: 'carmaintenanceinformation',
        component: () => import(
          '@/views/carmaintenanceinformation/carmaintenanceinformation.vue'
        ),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车辆管理',
              children: {
                title: '车辆维修信息管理'
              }
            }
          }
        }
      }, // 车辆维修信息管理
      {
        path: '/system/car/carDisinfectManagement',
        name: 'carDisinfectManagement',
        component: () => import(
          '@/views/carDisinfectManagement/carDisinfectManagement.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车辆管理',
              children: {
                title: '车辆消毒信息管理'
              }
            }
          }
        }
      }, // 车辆消毒信息管理
      {
        path: '/system/car/renew',
        name: 'renew',
        component: () => import(
          '@/views/renew/index.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车载管理',
              children: {
                title: '设备续费管理'
              }
            }
          }
        }
      } // 车辆消毒信息管理
      ]
    }, // 车辆管理
    {
      path: '/system/mobileUnit',
      name: 'mobileUnit',
      component: () => import('@/views/mobileUnit/mobileUnit.vue'),
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '车载设备管理'
          }
        }
      },
      children: [{
        path: '/system/mobileUnit/flat',
        name: 'flat',
        component: () => import(
          '@/views/flat/flat.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车载管理',
              children: {
                title: '平板账号管理'
              }
            }
          }
        }
      }, {
        path: '/system/mobileUnit/vehicleequipment',
        name: 'vehicleequipment',
        component: () => import(
          '@/views/vehicleequipment/vehicleequipment.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车载管理',
              children: {
                title: '车载设备信息管理'
              }
            }
          }
        }
      }, // 车载设备信息
      {
        path: '/system/mobileUnit/vehicleequipmentWarehousing',
        name: 'vehicleequipmentWarehousing',
        component: () => import(
          '@/views/vehicleequipmentWarehousing/vehicleequipmentWarehousing.vue'
        ),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车载管理',
              children: {
                title: '车载入库记录管理'
              }
            }
          }
        }
      }, // 车载设备入库记录
      {
        path: '/system/mobileUnit/vehicleequipmentstock',
        name: 'vehicleequipmentstock',
        component: () => import(
          '@/views/vehicleequipmentstock/vehicleequipmentstock.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车载管理',
              children: {
                title: '车载设备库存管理'
              }
            }
          }
        }
      }, // 车载设备库存信息
      {
        path: '/system/mobileUnit/vehicleequipmentDelivery',
        name: 'vehicleequipmentDelivery',
        component: () => import(
          '@/views/vehicleequipmentDelivery/vehicleequipmentDelivery.vue'
        ),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车载管理',
              children: {
                title: '车载设备出库管理'
              }
            }
          }
        }
      }, // 车载设备出库记录
      {
        path: '/system/mobileUnit/vehicleequipmentrepair',
        name: 'vehicleequipmentrepair',
        component: () => import(
          '@/views/vehicleequipmentrepair/vehicleequipmentrepair.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车载管理',
              children: {
                title: '车载设备维修信息管理'
              }
            }
          }
        }
      }, // 车载设备维修管理
      {
        path: '/system/mobileUnit/vehicleequipmentscrap',
        name: 'vehicleequipmentscrap',
        component: () => import(
          '@/views/vehicleequipmentscrap/vehicleequipmentscrap.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车载管理',
              children: {
                title: '车载设备报废管理'
              }
            }
          }
        }
      }, // 车载设备报废管理
      {
        path: '/system/mobileUnit/vehicleequipmenthdd',
        name: 'vehicleequipmenthdd',
        component: () => import(
          '@/views/vehicleequipmenthdd/vehicleequipmenthdd.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车载管理',
              children: {
                title: '车载硬盘数据备份记录管理'
              }
            }
          }
        }
      }, // 车载硬盘数据备份记录管理
      {
        path: '/system/mobileUnit/Medicalproduct',
        name: 'Medicalproduct',
        component: () => import('@/views/Medicalproduct/Medicalproduct.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '车载管理',
              children: {
                title: '医用设备（含药品）信息管理'
              }
            }
          }
        }
      } // 医用设备（含药品）信息管理
      ]
    }, // 车载设备管理
    {
      path: '/system/medic',
      name: 'medic',
      component: () => import('@/views/medic/medic.vue'),
      children: [{
        path: '/system/medic/driverlist',
        name: 'driverlist',
        component: () => import('@/views/driverlist/driverlist.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '医护人员管理',
              children: {
                title: '驾驶员管理管理'
              }
            }
          }
        }
      } // 驾驶员员管理
      ]
    }, // 医护人员管理
    {
      path: '/system/onBoard',
      name: 'onBoard',
      component: () => import('@/views/onBoard/onBoard.vue'),
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '随车人员管理'
          }
        }
      },
      children: [{
        path: '/system/onBoard/realtime',
        name: 'realtime',
        component: () => import('@/views/realtime/realtime.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '随车人员管理',
              children: {
                title: '随车人员实时状态管理'
              }
            }
          }
        }
      }, // 随车人员实时状态管理
      {
        path: '/system/onBoard/history',
        name: 'history',
        component: () => import('@/views/history/history.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '随车人员管理',
              children: {
                title: '随车人员历史状态管理'
              }
            }
          }
        }
      }, // 随车人员历史状态管理
      // { path: '/system/onBoard/train', name: 'train', component: () => import('@/views/train/train.vue') }, // 随车人员培训信息管理
      {
        path: '/system/onBoard/certificate',
        name: 'certificate',
        component: () => import('@/views/certificate/certificate.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '随车人员管理',
              children: {
                title: '随车人员证书信息管理'
              }
            }
          }
        }
      } // 随车人员证书信息管理
      ]
    }, // 随车人员管理
    {
      path: '/system/safety',
      name: 'safety',
      component: () => import('@/views/safety/safety.vue'),
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '安全准入管理'
          }
        }
      },
      children: [{
        path: '/system/safety/CertificateInformation',
        name: 'CertificateInformation',
        component: () => import(
          '@/views/CertificateInformation/CertificateInformation.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '安全准入管理',
              children: {
                title: '证书信息管理'
              }
            }
          }
        }
      }, // 证书信息管理
      {
        path: '/system/safety/certificate',
        name: 'certificate',
        component: () => import('@/views/certificate/certificate.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '安全准入管理',
              children: {
                title: '随车人员证书管理'
              }
            }
          }
        }
      }, // 随车人员证书管理
      {
        path: '/system/safety/trainingRecord',
        name: 'trainingRecord',
        component: () => import('@/views/trainingRecord/trainingRecord.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '安全准入管理',
              children: {
                title: '随车人员培训记录管理'
              }
            }
          }
        }
      }, // 随车人员培训记录管理
      {
        path: '/system/safety/trainingCourse',
        name: 'trainingCourse',
        component: () => import('@/views/trainingCourse/trainingCourse.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '安全准入管理',
              children: {
                title: '随车人员培训科目信息'
              }
            }
          }
        }
      } // 随车人员培训科目信息
      ]
    }, // 安全准入管理
    {
      path: '/system/finance',
      name: 'finance',
      component: () => import('@/views/finance/finance.vue'),
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '财务管理'
          }
        }
      },
      children: [{
        path: '/system/finance/financeManage',
        name: 'financeManage',
        component: () => import('@/views/financeManage/financeManage.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '财务管理',
              children: {
                title: '财务结算申请'
              }
            }
          }
        }
      }, // 财务结算申请
      {
        path: '/system/financePage/financePage',
        name: 'financePage',
        component: () => import('@/views/financePage/financePage.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '财务管理',
              children: {
                title: '财务结算审批'
              }
            }
          }
        }
      }, // 财务结算审批
      {
        path: '/system/financeQuery/financeQuery',
        name: 'financeQuery',
        component: () => import('@/views/financeQuery/financeQuery.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '财务管理',
              children: {
                title: '财务结算查询'
              }
            }
          }
        }
      }, // 财务结算查询
      {
        path: '/system/financeQuery/financialmonthlyreport',
        name: 'financeQuery',
        component: () => import(
          '@/views/financialmonthlyreport/financialmonthlyreport.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '财务管理',
              children: {
                title: '财务月季报表'
              }
            }
          }
        }
      } // 财务月季报表
      ]
    }, // 财务管理
    {
      path: '/system/basicSetting',
      name: 'basicSetting',
      component: () => import('@/views/basicSetting/basicSetting.vue'),
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '基础设置'
          }
        }
      },
      children: [{
        path: '/system/basicSetting/transportType',
        name: 'transportType',
        component: () => import('@/views/transportType/transportType.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '基础设置',
              children: {
                title: '转运类型管理'
              }
            }
          }
        }
      }, // 转运类型管理
      {
        path: '/system/basicSetting/cartype',
        name: 'cartype',
        component: () => import('@/views/cartype/cartype.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '基础设置',
              children: {
                title: '转运车类型管理'
              }
            }
          }
        }
      }, // 转运车类型管理
      {
        path: '/system/basicSetting/TransshipmentCharges',
        name: 'TransshipmentCharges',
        component: () => import(
          '@/views/TransshipmentCharges/TransshipmentCharges.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '基础设置',
              children: {
                title: '转运收费标准管理'
              }
            }
          }
        }
      }, // 转运收费标准管理'
      {
        path: '/system/basicSetting/ProportionPrepaid',
        name: 'ProportionPrepaid',
        component: () => import(
          '@/views/ProportionPrepaid/ProportionPrepaid.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '基础设置',
              children: {
                title: '转运预付费管理'
              }
            }
          }
        }
      }, // 转运预付费管理'
      {
        path: '/system/basicSetting/dispatcherArea',
        name: 'dispatcherArea',
        component: () => import('@/views/dispatcherArea/dispatcherArea.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '基础设置',
              children: {
                title: '调度员区域关联管理'
              }
            }
          }
        }
      }, // 调度员区域关联管理'
      {
        path: '/system/basicSetting/vehicleboardassociation',
        name: 'vehicleboardassociation',
        component: () => import(
          '@/views/vehicleboardassociation/vehicleboardassociation.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '基础设置',
              children: {
                title: '车辆与车载关联管理'
              }
            }
          }
        }
      } // 车辆与车载关联管理'
      ]
    }, // 基础设置
    {
      path: '/system/peoManagement',
      name: 'peoManagement',
      component: () => import('@/views/peoManagement/peoManagement.vue'),
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '人员管理'
          }
        }
      },
      children: [{
        path: '/system/peoManagement/cardministration',
        name: 'cardministration',
        component: () => import(
          '@/views/cardministration/cardministration.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '人员管理',
              children: {
                title: '驾驶员管理'
              }
            }
          }
        }
      }, // 驾驶员管理
      {
        path: '/system/peoManagement/mediclist',
        name: 'mediclist',
        component: () => import('@/views/mediclist/mediclist.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '人员管理',
              children: {
                title: '医护人员管理'
              }
            }
          }
        }
      }, // 医护人员管理
      {
        path: '/system/peoManagement/peohistoristate',
        name: 'peohistoristate',
        component: () => import('@/views/peohistoristate/peohistoristate.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '人员管理',
              children: {
                title: '随车人员历史状态信息'
              }
            }
          }
        }
      }, // 随车人员历史状态信息
      {
        path: '/system/peoManagement/peoLineManagement',
        name: 'peoLineManagement',
        component: () => import(
          '@/views/peoLineManagement/peoLineManagement.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '人员管理',
              children: {
                title: '随车人员上下线状态信息管理'
              }
            }
          }
        }
      }, // 随车人员上下线状态信息管理
      {
        path: '/system/peoManagement/dispatcherpunchin',
        name: 'dispatcherpunchin',
        component: () => import(
          '@/views/dispatcherpunchin/dispatcherpunchin.vue'),
        meta: {
          bread: {
            title: '系统管理',
            children: {
              title: '人员管理',
              children: {
                title: '调度员上下线打卡管理'
              }
            }
          }
        }
      } // 调度员上下线打卡管理
      ]
    }, // 人员管理
    {
      path: '/system/hospitalManagement',
      name: 'hospitalManagement',
      component: () => import('@/views/hospitalManagement/index.vue'),
      meta: {
        bread: {
          title: '系统管理',
          children: {
            title: '医院信息统计'
          }
        }
      },
      children: [
        {
          path: '/system/hospitalManagement/Information',
          name: 'Information',
          component: () => import(
            '@/views/hospitalManagement/Information.vue'),
          meta: {
            bread: {
              title: '系统管理',
              children: {
                title: '医院信息统计',
                children: {
                  title: '医院信息管理'
                }
              }
            }
          }
        },
        {
          path: '/system/hospitalManagement/userManagement',
          name: 'userManagement',
          component: () => import(
            '@/views/hospitalManagement/userManagement.vue'),
          meta: {
            bread: {
              title: '系统管理',
              children: {
                title: '医院信息统计',
                children: {
                  title: '用户管理'
                }
              }
            }
          }
        }
      ]
    }
    ]
  }, // 业务管理
  {
    path: '/operationManage',
    name: 'operationManage',
    component: () => import('@/views/operationManage/operationManage.vue'),
    meta: {
      bread: {
        title: '运营管理',
        children: {
          title: '事件回溯'
        }
      }
    },
    children: [{
      path: '/track',
      name: 'track',
      component: () => import('@/views/track/track.vue'),
      meta: {
        bread: {
          title: '运营管理',
          children: {
            title: '事件回溯',
            children: {
              title: '轨迹回放'
            }
          }
        }
      }
    }, // 轨迹回放
    {
      path: '/playback',
      name: 'playback',
      component: () => import('@/views/playback/playback.vue'),
      meta: {
        bread: {
          title: '运营管理',
          children: {
            title: '事件回溯',
            children: {
              title: '视频回放'
            }
          }
        }
      }
    } // 视频回放
    ]
  }, // 事件回溯
  {
    path: '/monitoring',
    name: 'monitoring',
    component: () => import('@/views/monitoring/monitoring.vue'),
    meta: {
      bread: {
        title: '运营管理',
        children: {
          title: '实时监控'
        }
      }
    }
  }

  ]
},
{
  path: '/bigScreen',
  name: 'bigScreen',
  component: () => import('@/views/bigScreen/bigScreen.vue')
}, // 大屏
{
  path: '/login',
  name: 'login',
  component: () => import('@/views/login/login.vue')
} // 登录
]
const route = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  } // 登录
]
const router = new VueRouter({
  mode: 'hash',
  routes: routes
})
router.beforeEach((to, from, next) => {
  // if (sessionStorage.getItem('token')) {
  if (to.path === '/login') {
    next()
  } else {
    if (sessionStorage.token !== null) {
      if (sessionStorage.getItem('headerNav')) {
        var nav = JSON.parse(sessionStorage.getItem('headerNav'))
        var navRouter = nav.map(item => item.MENU_URL)
        console.log(navRouter, to, to.path.includes('/operation'), navRouter.includes('/operation'))
        if (to.path.includes('/operation') && !navRouter.includes('/operation')) {
        //   console.log('暂无权限')
          Message.error('暂无权限')
          router.go(-1)
          return false
        }
      }
      // router.addRoutes(JSON.parse(sessionStorage.getItem('asyncRputer')))
      next()
    } else {
      next('/login')
    }
  }
})
export default router
