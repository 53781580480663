import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import './plugins/element.js'
import '@/styles/common.css'
// import 'lib-flexible'
import './lib/flexible'
import 'default-passive-events'
// import 'axios'
import axios from 'axios'
import store from './store/index.js'
import './config/rem'
import { Message } from 'element-ui'
// 按钮组件 全局使用
import KtButton from '@/permission/ktButton.vue'
import loadMore from '@/directive/loadMore'
import inputNumber from '@/directive/inputNumber'
import permission from '@/directive/permission'

Vue.prototype.$eventBus = new Vue()
Vue.component('KtButton', KtButton)
// axios.defaults.baseURL = 'http://192.168.123.193:8701/userManageServer'
// axios.defaults.baseURL = 'http://192.168.123.196:8908'

axios.defaults.baseURL = '/api' // 下拉加载更多
Vue.use(loadMore)
Vue.use(inputNumber)
Vue.use(permission)
// 请求拦截器
axios.interceptors.request.use(config => {
  const headers = config.data ? config.data.headers : {}
  config.headers.ORDER_COMPANY = store.state.order_company || sessionStorage.getItem('order_company')
  config.headers.AUTH_TYPE = store.state.auth_type || sessionStorage.getItem('auth_type')
  config.headers = Object.assign({}, config.headers, headers)
  if (config.data) {
    delete config.data.headers
  }
  config.headers.Authorization = sessionStorage.getItem('token')
  if (config.data && config.data.$_isFormData === false) {
    config.headers['Content-Type'] = 'multipart/form-data'
    // config.headers.Authorization = {
    //   'Content-Type': 'multipart/form-data'
    // }
    // if (sessionStorage.getItem('token') !== null) {
    //     config.headers.Authorization['token'] = JSON.stringify(sessionStorage.getItem('token'))
    // }
  } else {
    config.headers['Content-Type'] = 'application/json'
    // config.headers.Authorization = {
    //   'Content-Type': 'application/json',
    //   'Access-Control-Allow-Origin': '*'
    // }
  }
  return config
})
// 返回拦截
axios.interceptors.response.use((response) => {
  // 获取接口返回结果
  const res = response
  //   console.log(res, 'res.statusCode')
  // code为0，直接把结果返回回去，这样前端代码就不用在获取一次data.
  //   if (res.data.statusCode == 200 || res.data.status == 200 || (res.status == 200 && res.data.result)) {
  //     return res
  //   } else
  if (res.data.statusCode == 201 || res.data.status == 201) {
    Message.error(res.data.resultDesc || '网络请求异常，请稍后重试!')
  } else {
    return res
    // Message.error(res.data.resultDesc || '网络请求异常，请稍后重试!')
  }
  return res
}, (err) => {
  const rej = err.response
  //   console.log(rej, 'reject')
  //   if (rej.status == 500 || rej.status == 503 || rej.statusCode == 500 || rej.statusCode == 503) {
  Message.error('网络请求异常，请稍后重试!')
//   } else {
//     Message.error(rej.data.message || rej.statusText || rej.message || '网络请求异常，请稍后重试!')
//   }
})
Vue.prototype.$http = axios
Vue.config.productionTip = false

// 全局守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    var token = sessionStorage.getItem('token')
    if (token) {
      next()
    } else {
      next('/login')
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
